if ('define' in window) {
define("discourse/theme-20/discourse/pre-initializers/theme-20-translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "theme-20-translations",
    initialize() {
      /* Translation data for theme 20 (en)*/
      const data = {
        "en": {
          "close": {
            "title": "Close the banner",
            "label": ""
          },
          "toggle": {
            "title": "Expand/Collapse the banner",
            "collapse_label": "",
            "expand_label": ""
          }
        }
      };
      for (let lang in data) {
        let cursor = I18n.translations;
        for (let key of [lang, "js", "theme_translations"]) {
          cursor = cursor[key] = cursor[key] || {};
        }
        cursor[20] = data[lang];
      }
    }
  };
});
}
